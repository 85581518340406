@charset 'utf-8';
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap');
$size-1: 3rem;
$size-2: 2.25rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.65rem;
$footer-padding: 1.2rem 1rem 1.2rem;
$footer-background-color: hsl(0, 0%, 96%);
@import './../../node_modules/bulma/bulma.sass';

body {
  font-family: 'Press Start 2P', cursive
}

.avatar {
  height: 256px;
  width: 256px;
}

.hero.is-small .hero-body {
  padding-top: 4rem;
  padding-bottom: 3.5rem;
}

@media (min-width: 1024px) {
  .hero.is-small .hero-body {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
  .purpleNavBar {
    padding-left: 3rem;
  }
}

@media (max-width: 400px) {
  .avatar {
    height: 168px;
    width: 168px;
  }
  .hero.is-small .hero-body {
    padding-top: 4rem;
    padding-bottom: 1rem;
  }
}

.purpleNavBar {
  background-color: #4F43AE;
}

.navBarText {
  color: white;
}
  